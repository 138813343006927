import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import mainPhoto from "../../assets/photos/mainPhoto1.png"
import mainPhotoMobile from "../../assets/photos/mainPhotoMobile.png"
import secondPhoto from "../../assets/photos/secondPhoto2.png"
import secondPhotoMobile from "../../assets/photos/secondPhotoMobile.png"
import thirdPhoto from "../../assets/photos/thirdPhoto.png"
import truck1 from "../../assets/photos/truck1.png"
import area from "../../assets/photos/area.png"
import area2 from "../../assets/photos/area2.png"


import {useTranslation} from "react-i18next";
import {Image} from "@nextui-org/react";
import {useTheme} from "next-themes";
import useMediaQuery from '@mui/material/useMediaQuery';
import {Slide} from "react-reveal";
import Carousel from "react-multi-carousel";
import ProjectShowcaseComponent from "../../components/ProjectShowcase/ProjectShowcaseComponent";
// import TabInfoComponent from "../../components/TabInfo/TabInfoComponent";
import BackToTopComponent from "../../components/BackToTop/BackToTopComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import transpetLogo from "../../assets/transpet.png"
import video from "../../assets/video.mp4"
import logo from "../../assets/logo.png"

const HomePage = () => {
    const {t} = useTranslation()
    const {theme, setTheme} = useTheme()
    const isMobile = useMediaQuery('(max-width: 640px)'); // Assuming 640px is your mobile breakpoint

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.

        }
    };

    const references = [
        {
            name: "TPAO",
            imgUrl: "https://cdn.memleket.com.tr/news/304254.jpg",
        },
        {
            name: "Mercedes",
            imgUrl: "https://img1.cgtrader.com/items/4060860/05ef4c642c/large/mercedes-benz-logo-3d-3d-model-05ef4c642c.jpg"
        },
        {
            name: "Mobiliz",
            imgUrl: "https://play-lh.googleusercontent.com/ol6-4-2w551jO7cjr1gQOHFeO_DaCXJoB7oMuAUpWbxP5NVBg4G_saVjyCdEoR5S0A",
        },
        {
            name: "Transpet",
            imgUrl: "https://nakkasholding.com.tr/media/b3jbipee/transpet.png",
        },
        {
            name: "Global",
            imgUrl: "https://nakkasholding.com.tr/media/ggwg2dlf/global_gaz.png",
        }
    ];

    
    const tabsData = [
        {
            label: "Bölüm 1",
            imgUrl: truck1,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
            label: "Bölüm 2",
            imgUrl: area,
            desc: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        },
        {
            label: "Bölüm 3",
            imgUrl: area2,
            desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
    ];
    
    const settings = {
        dots: true,
        infinite: true,
        fade: true,
        autoplay: true,
        speed: 3000,
        arrows: false,
        autoplaySpeed: 4000,
    };
    
    const [loadingTimer,setLodingTimer] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLodingTimer(false)
        },3000)
    }, []);
    
    return (
        <div className={""}>
            <LoadingComponent loadingTimer={loadingTimer}/>
            
            {!loadingTimer && (
                <>
                    <div className={""}>
                        <Slider {...settings}>
                            <div className="relative">
                                <img src={isMobile ? mainPhotoMobile : mainPhoto} alt="Image 1"
                                    className="w-full object-cover h-50 sm:h-48 md:h-96 lg:h-[40rem]"/>
                                {/*bg-gradient-to-r from-yellow-600/70 to-transparent*/}
                                {isMobile ? (
                                    <div className="absolute inset-0 flex justify-start p-5 pt-[7rem]">
                                        <p className="text-white font-extrabold italic text-md ">
                                            {t("mainIdea").split(' ').map((word, index) => (
                                                (index > 0 && index % 3 === 0) ? (
                                                    <>
                                                        <br/>{`${word} `}
                                                    </>
                                                ) : (
                                                    <>
                                                        {`${word} `}
                                                    </>
                                                )
                                            ))}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="absolute inset-0 flex justify-start p-10 pt-20">
                                        <h2 className="text-white font-extrabold italic text-3xl md:text-3xl lg:text-6xl">
                                            {t("mainIdea").split(' ').map((word, index) => (
                                                (index > 0 && index % 3 === 0) ? (
                                                    <>
                                                        <br/>{`${word} `}
                                                    </>
                                                ) : (
                                                    `${word} `
                                                )
                                            ))}
                                        </h2>
                                    </div>
                                )}

                            </div>
                            <div>
                                <img src={isMobile ? secondPhotoMobile : secondPhoto} alt="Image 2"
                                    className="w-full object-cover h-50 sm:h-48 md:h-96 lg:h-[40rem]"/>

                                {isMobile ? (
                                    <div
                                        className="absolute left-1/2 transform -translate-x-1/2 flex justify-start text-center top-[2rem]">
                                        <h2 className="text-white font-extrabold italic text-xl">
                                            {t("mainIdea").split(' ').map((word, index) => (
                                                (index > 0 && index % 2 === 0) ? (
                                                    <>
                                                        <br/> {`${word} `}
                                                    </>
                                                ) : (
                                                    `${word} `
                                                )
                                            ))}
                                        </h2>
                                    </div>
                                ) : (
                                    <div
                                        className="absolute left-1/2 transform -translate-x-1/2  flex justify-center top-[2.5rem] text-center">
                                        <h2 className="text-white font-extrabold italic text-3xl md:text-3xl lg:text-6xl">
                                            {t("mainIdea").split(' ').map((word, index) => (
                                                (index > 0 && index % 3 === 0) ? (
                                                    <>
                                                        <br/>{`${word} `}
                                                    </>
                                                ) : (
                                                    `${word} `
                                                )
                                            ))}
                                        </h2>
                                    </div>

                                )}
                            </div>
                            <div>
                                <img src={thirdPhoto} alt="Image 3"
                                    className="w-full object-cover h-50 sm:h-64 md:h-96 lg:h-[40rem]"/>
                            </div>
                        </Slider>
                    </div>


                    <div className={"container mx-auto mt-10 mb-20"}>
                        <Slide bottom duration={1000}>
                            <div className={"about my-20 mx-6"} style={{background: "", color: ""}}>

                                <div className={""}>
                                    <p
                                        className={"sm:text-4xl text-2xl text-center mb-0 font-semibold"}>{t("about").toLocaleUpperCase()}
                                    </p>
                                </div>


                                <div className={"w-full block sm:flex items-center"}>

                                    <svg id="10015.io" viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <defs>
                                            <clipPath id="blob">

                                                <path fill="#474bff"
                                                    d="M406.5,290Q396,340,359.5,380.5Q323,421,271,405Q219,389,180.5,371Q142,353,76.5,330Q11,307,19.5,242.5Q28,178,83.5,151Q139,124,175.5,83Q212,42,274.5,34.5Q337,27,385,71.5Q433,116,425,178Q417,240,406.5,290Z"/>
                                            </clipPath>
                                        </defs>
                                        <image x="0" y="0" width="100%" height="100%" clipPath="url(#blob)"
                                            xlinkHref="https://img.piri.net/mnresize/712/-/piri/upload/3/2023/10/24/77b63f02-yilgz5he0nr97y0cklim.jpeg"
                                            preserveAspectRatio="xMidYMid slice"></image>
                                    </svg>

                                    <div className={"font-semibold text-xl mx-4"}>
                                        <p>
                                        Türkiye Cumhuriyeti ile Irak arasında, Karayolu ile transit petrol ve LPG nakliyesi ile Transpet Petrol A.Ş. ile Global Gas Trans bünyesinde taşınan ürünler(Bitumen,Fuel Oil,Motorin,Nafta ve benzeri petrol ürünleri) 2019'da 50 araç ile başlamış olup şuanda 600 araç ile hizmet vermektedir. 
                                        </p>               
                                        <br />                 
                                        <p>

                                    Oil Trans ayrıca ülkemiz için büyük önem arz eden Gabar Petrollerinin ham petrol karayolu taşımacılığında Gabardan, Adıyaman ve Dörtyol tesislerine taşınmasında 324 araç ile hizmet vermiştir.
                                        </p>


                                    </div>
                                </div>


                            </div>
                        </Slide>

                        <div className={"transportation my-20"}>
                            <p className={"sm:text-4xl text-2xl text-center mb-10 font-semibold"}>
                                 Tanıtım Videomuz
                            </p>

                            <div className="video-container relative w-full max-w-4xl mx-auto rounded-xl overflow-hidden shadow-2xl">
                                <div className="aspect-w-16 aspect-h-9">
                                    <video
                                        controls

                                        poster={logo} // Video thumbnail URL'ini buraya ekleyin
                                    >
                                        <source src={video} type="video/mp4" /> 
                                    </video>
                                </div>
                            </div>
                        </div>

                        <div className={"projects mt-20"}>
                            <div className={"mb-5"}>
                                <p
                                    className={"sm:text-4xl text-2xl text-center mb-0 font-semibold"}>{t("projects").toLocaleUpperCase()}
                                </p>
                            </div>

                            <div className={"projects-content-"}>
                                <Carousel
                                    additionalTransfrom={0}
                                    swipeable={false}
                                    draggable={false}
                                    responsive={responsive}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    arrows={true}
                                    autoPlay={true}
                                    autoPlaySpeed={5000} // Otomatik oynatma hızını 5000ms yapın
                                    keyBoardControl={true}
                                    customTransition="all 2s ease-in-out" // Daha yavaş ve yumuşak bir geçiş efekti için değiştirilmiş
                                    transitionDuration={2000} // Geçiş süresini 2000ms yapın
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    itemClass=""
                                >
                                    <ProjectShowcaseComponent
                                        title={"TPAO Gabar Petrol Taşıma Projesi"}
                                        desc={"Oil Trans olarak Gabar Petrollerinin ham petrol taşımacılığında Gabardan, Adıyaman ve Dörtyol tesislerine 75'i özmal olup, toplamda 324 araç ile hizmet vermiştir,Bu süreç zarfında yöre halkının istihdamına önemli ölçüde katkıda bulundu."}
                                        link={"https://google.com"}
                                        img={truck1}/>

                                    <ProjectShowcaseComponent
                                        title={"Irak Transit Petrol Ve LPG Projesi"}
                                        desc={"Türkiye Cumhuriyeti ve Irak arasında yapılan LPG ve Transit taşımacılık projemizde 2019'da 50 araç ile başlamış olup güncel olarak 600 araç ile proje nakliyesi üzerinde hizmet vermektedir."}
                                        link={"https://google.com"}
                                        img={area}/>

                                    <ProjectShowcaseComponent
                                        title={"Syol "}
                                        desc={"Türkiye Cumhuriyeti ve Irak arasında,2013-2016 sürecinde diğer Firmamız olan Syol Enerji ile karayolu ile petrol taşımacılığında, 1200 araç ile condensate,motorin,ham petrol,ağır ham petrol,nafta ve benzeri petrol türevlerini güvenli ve hızlı bir şekilde taşımıştır."}
                                        link={"https://google.com"}
                                        img={area2}/>


                                </Carousel>
                            </div>
                        </div>


                        <div className={"references mt-16"}>
                            <div className={"mb-10"}>
                                <p
                                    className={"sm:text-4xl text-2xl text-center mb-0 font-semibold"}>{t("references").toLocaleUpperCase()}
                                </p>
                            </div>

                            <div className="references w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                                {references.map((reference, index) => (
                                    <div key={index} className={`flex flex-col items-center px-8 ${reference.name === "Transpet" ? "mt-32" : ""} ${reference.name === "Global" ? "mt-32" : ""}`}>
                                        <Image
                                            src={reference.imgUrl}
                                            alt={reference.name}
                                            className={`${
                                                reference.name === "Global" || reference.name === "Transpet" ? "rounded-full" : ""
                                              }`}
                                              width={reference.name === "Global" || reference.name === "Transpet" ? 350 : 200}
                                              height={reference.name === "Global" || reference.name === "Transpet" ? 350 : 200}
                                              
                                        />
                                        <p className="font-semibold my-2 text-xl">{reference.name}</p>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </>
            )}

            <BackToTopComponent/>
        </div>

    );
}

export default HomePage;