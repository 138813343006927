import {Progress,Divider} from "@nextui-org/react";
import ProjectComponent from "../../components/Project/ProjectComponent";
import truck1 from "../../assets/photos/truck1.png"
import area from "../../assets/photos/area.png"
import area2 from "../../assets/photos/area2.png"
import {useTranslation} from "react-i18next";


const ProjectsPage = () => {
    const {t} = useTranslation()
    
    const images = [
   truck1,area,area2
    ];    
    
    const projects = [
        {
            title: "TPAO Gabar Petrol Taşıma Projesi",
            description: "Oil Trans olarak Gabar Petrollerinin ham petrol taşımacılığında Gabardan, Adıyaman ve Dörtyol tesislerine 75'i özmal olup, toplamda 324 araç ile hizmet vermiştir. Bu süreç zarfında yöre halkının istihdamına önemli ölçüde katkıda bulundu.",
            image: truck1,
            date: "2023 - Devam Ediyor"
        },
        {
            title: "Irak Transit Petrol Ve LPG Projesi",
            description: "Türkiye Cumhuriyeti ve Irak arasında yapılan LPG ve Transit taşımacılık projemizde 2019'da 50 araç ile başlamış olup güncel olarak 600 araç ile proje nakliyesi üzerinde hizmet vermektedir.",
            image: area,
            date: "2019 - Devam Ediyor"
        },
        {
            title: "Syol Enerji Projesi",
            description: "Türkiye Cumhuriyeti ve Irak arasında, 2013-2016 sürecinde diğer Firmamız olan Syol Enerji ile karayolu ile petrol taşımacılığında, 1200 araç ile condensate, motorin, ham petrol, ağır ham petrol, nafta ve benzeri petrol türevlerini güvenli ve hızlı bir şekilde taşımıştır.",
            image: area2,
            date: "2013 - 2016"
        }
    ];
    
    return(
        <div>
            

            <section className="bg-white pb-6 dark:bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
                
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">{t("projects")}</h1>
                <div className={"text-center flex justify-center my-5"}>
                    <Progress
                        size="sm"
                        color={"success"}
                        isIndeterminate
                        aria-label="Loading..."
                        className="max-w-md"
                    />
                </div>
                    <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
                    Firmamız, lojistik ve taşımacılık sektöründe hayata geçirdiği yenilikçi projelerle güvenilir hizmet anlayışını bir adım öteye taşımaktadır. Türkiye’nin stratejik noktalarında gerçekleştirdiğimiz ham petrol, LPG ve transit taşımacılık projeleriyle hem ekonomik katkı sağlamakta hem de bölge halkına istihdam olanakları yaratmaktayız. Gelişmiş araç filolarımız, ileri teknoloji takip sistemlerimiz ve uzman ekibimizle, sektörün en yüksek standartlarını karşılayan çözümler sunuyoruz. Projelerimizle, müşterilerimize hızlı, güvenli ve çevre dostu taşımacılık deneyimi yaşatmaya devam ediyoruz.
                    </p>
                    <Divider className="my-4" />

                    
                    
                 
                </div>
                <div className={"pt-20 mx-10 sm:mx-auto sm:container"}>
                    <ProjectComponent
                        title={"TPAO Gabar Petrol Projesi"}
                        year={"2024 / 2025"}
                        images={images}
                        desc="
              Oil Trans olarak, Gabar Petrolleri'nin ham petrol taşımacılığı alanındaki faaliyetlerimizde, Gabar'dan Adıyaman ve Dörtyol tesislerine güvenilir ve kesintisiz hizmet sunmaktan gurur duyuyoruz. Filomuzda yer alan 75'i özmal olmak üzere toplamda 324 araç ile gerçekleştirdiğimiz taşımacılık operasyonları, yalnızca sektörel ihtiyaçlara çözüm sunmakla kalmayıp aynı zamanda yöre halkına önemli istihdam olanakları sağlayarak bölge ekonomisine de büyük katkı sağlamıştır.

Taşımacılık süreçlerinde güvenlik ve verimliliği en üst seviyede tutmak amacıyla araçlarımız, 3 adet kamera sistemi ve GPS tabanlı araç takip teknolojileri ile donatılmıştır. Ayrıca, operasyonlarımızı sürekli olarak denetleyen gezici ekiplerimiz, taşımacılığın her aşamasında maksimum güvenliği sağlamaktadır.
                        "
                    />
                    
                    
                    <ProjectComponent
                        title={"Irak Transit Petrol Ve LPG Projesi"}
                        images={[area2]}
                        year={"2013 / 2016"}
                        desc="
Türkiye Cumhuriyeti ve Irak arasında gerçekleştirdiğimiz LPG ve transit taşımacılık projemiz, 2019 yılında 50 araçlık bir filo 
ile faaliyete başlamış olup, geçen süreçte gösterilen özverili çalışmalar ve müşteri memnuniyetine verilen önem sayesinde büyüyerek 
bugün 600 araçlık dev bir filo ile hizmet vermeye devam etmektedir. 
Projemiz, sektörün en yüksek standartlarına uygun, güvenli, hızlı ve çevre dostu taşımacılık anlayışıyla çalışmaktadır.
                        "
                        />
                        
                        
                    </div>
                <div className="bg-gradient-to-b from-green-200 to-transparent dark:from-green-900 w-full dark:h-[40rem] absolute top-0 left-0 z-0"></div>
            </section>
            
            
        </div>
        )
    
}

export default ProjectsPage;