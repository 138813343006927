import React from 'react';
import {Spinner} from "@nextui-org/react";
import {useTheme} from "next-themes";
import {useTranslation} from "react-i18next";

const LoadingComponent = ({loadingTimer}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();

    // Eğer loading durumu false ise hiçbir şey render etme
    if (!loadingTimer) return null;

    return (
        <div
            className={`fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 ${
                theme === "light" ? "bg-white" : "bg-black"
            }`}
            style={{
                opacity: loadingTimer ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out'
            }}
        >
            <Spinner 
                label={`${t("loading")}...`} 
                color="warning" 
                size="lg" 
                labelColor="warning"
                className="transition-all duration-300 ease-in-out"
            />
        </div>
    );
};

export default LoadingComponent;
