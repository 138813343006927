import {Progress,Divider} from "@nextui-org/react";


const AboutPage = () => {
    
    
    return(
        <div>
            

            <section className="bg-white dark:bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
                   
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Biz Kimiz ?</h1>
                   <div className={"text-center flex justify-center my-5"}>
                       <Progress
                           size="sm"
                           color={"primary"}
                        isIndeterminate
                        aria-label="Loading..."
                        className="max-w-md"
                    />
                   </div>
                    <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
                    <p>
                                Türkiye Cumhuriyeti ile Irak arasında, Karayolu ile transit petrol ve LPG nakliyesi ile Transpet Petrol A.Ş. ile Global Gas Trans bünyesinde taşınan ürünler(Bitumen,Fuel Oil,Motorin,Nafta ve benzeri petrol ürünleri) 2019'da 50 araç ile başlamış olup şuanda 600 araç ile hizmet vermektedir. 
                                    </p>               
                                    <br />                 
                                    <p>

                                Oil Trans ayrıca ülkemiz için büyük önem arz eden Gabar Petrollerinin ham petrol karayolu taşımacılığında Gabardan, Adıyaman ve Dörtyol tesislerine taşınmasında 324 araç ile hizmet vermiştir.
                                    </p>

                    </p>
                    <Divider className="my-4" />

                </div>
                <div className="bg-gradient-to-b from-sky-200 to-transparent dark:from-sky-950 w-full dark:h-full absolute top-0 left-0 z-0"></div>
            </section>
            
            
            

<section className="bg-white dark:bg-black mt-20">
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
        <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12 mb-8">
            <h1 className="text-gray-900 dark:text-white text-3xl md:text-5xl font-extrabold mb-2">900'den Fazla Araç İle Hizmetteyiz</h1>
            <p className="text-lg font-normal text-gray-500 dark:text-gray-400 mb-6">
                Türkiye'nin önde gelen lojistik şirketlerinden biri olarak, modern ve güçlü araç filomuzla müşterilerimize kesintisiz hizmet sunmaktayız. Her geçen gün büyüyen filomuz ve deneyimli ekibimizle, sektörde güvenilir bir çözüm ortağı olmaya devam ediyoruz.
            </p>
            <a href="#" className="inline-flex justify-center items-center py-2.5 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                Detaylı Bilgi
                <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
            </a>
        </div>
        <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">
                <h2 className="text-gray-900 dark:text-white text-3xl font-extrabold mb-2">Hizmetlerimiz</h2>
                <p className="text-lg font-normal text-gray-500 dark:text-gray-400 mb-4">
                    • Uluslararası petrol ve türevleri taşımacılığı<br/>
                    • LPG taşımacılığı<br/>
                    • Ham petrol taşımacılığı<br/>
                    • Yurt içi akaryakıt taşımacılığı<br/>
                    • Proje bazlı özel taşımacılık çözümleri
                </p>
                <a href="#" className="text-blue-600 dark:text-blue-500 hover:underline font-medium text-lg inline-flex items-center">
                    Daha Fazla Bilgi
                    <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </a>
            </div>
            <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">
                <h2 className="text-gray-900 dark:text-white text-3xl font-extrabold mb-2">Hedeflerimiz</h2>
                <p className="text-lg font-normal text-gray-500 dark:text-gray-400 mb-4">
                    • Filo büyüklüğümüzü 1500 araca çıkarmak<br/>
                    • Yeni uluslararası rotalar eklemek<br/>
                    • Çevre dostu taşımacılık çözümleri geliştirmek<br/>
                    • Dijital dönüşüm projelerini hayata geçirmek<br/>
                    • Müşteri memnuniyetini en üst seviyede tutmak
                </p>
                <a href="#" className="text-blue-600 dark:text-blue-500 hover:underline font-medium text-lg inline-flex items-center">
                    Detaylı Bilgi
                    <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
    
</section>



            
    </div>
    )
    
}

export default AboutPage;